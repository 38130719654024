import AuthService from '../services/auth.service'
import CompanyService from '../services/company.service'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user ? { status: { loggedIn: true }, user } : { status: { loggedIn: false }, user: null };
const snackbar = {
    show: false,
    message: '',
    color: ''
}
export const auth = {
    namespaced: true,
    state: {...initialState, snackbar },
    actions: {
        clearSnack({ commit }) {
            commit('clearSnack');
        },
        login({ commit }, user) {
            return AuthService.login(user).then( 
                response => {
                    commit('loginSuccess', response.data)
                    return Promise.resolve(response.data)
                },
                error => {
                    commit('loginFailure', error)
                    return Promise.reject(error)
                }
            )
        },
        logout({ commit }) {
            AuthService.logout()
            commit('logout')
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    if (response.data.status == 200) {
                        commit('registerSuccess', response)
                        return Promise.resolve(response.data)
                    } else {
                        commit('validationError', response.data.error)
                        return Promise.reject(response)
                    }
                },
                error => {
                    commit('registerFailure', error)
                    return Promise.reject(error)
                }
            )
        },
        updateCompany({ commit }, company) {
            return CompanyService.update(company).then(
                response => {
                    commit('updateSuccess', response.data)
                    return Promise.resolve(response.data)
                },
                error => {
                    commit('updateFailure', error)
                    return Promise.reject(error)
                }
            )
        },
        resetCompanylogo({ commit }, logo) {
            commit('resetCompanylogo', logo)
        },
    },
    mutations: {
        // handler: () => { },
        loginSuccess(state, response) {
            state.status.loggedIn = true
            localStorage.setItem('user', JSON.stringify(response.data.user))
            localStorage.setItem('etims_integrated', JSON.stringify({
                etims_integrated: response.data.user.company.etims_integrated == 1 ? true : false,
                tin: response.data.user.company.tin,
                bhfId: response.data.user.company.bhfId,
                dvcSrlNo: response.data.user.company.dvcSrlNo
            }));
            localStorage.setItem('token', response.data.access_token)
            state.user = response.data.user
        },
        loginFailure(state, error) {
            state.status.loggedIn = false
            state.user = null
            console.log(error)
        },
        logout(state) {
            state.status.loggedIn = false
            state.user = null
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },
        registerSuccess(state, response) {
            state.status.loggedIn = true
            state.snackbar.show = true
            state.snackbar.color = 'success'
            state.snackbar.message = 'User registration successful!'
            state.user = response.data.user
        },
        registerFailure(state, error) {
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = error.response.data.message || error.response.data.errors
        },
        validationError(state, errors) {
            let msg = ''
            let errorsArray = Object.values(errors);
            console.log(errorsArray)
            errorsArray.forEach(element => {
                console.log(element)
                msg = msg + element + '.'
            });
            state.snackbar.show = true
            state.snackbar.color = 'error'
            state.snackbar.message = msg
        },
        clearSnack(state) {
            state.snackbar.show = false
            state.snackbar.color = ''
            state.snackbar.message = ''
        },
        updateSuccess(state, response) {
            console.log(response.data);
            state.user.company = response.data
        },
        updateFailure(state, error) {
            console.log(error);
            // state.user.company = null
        },
        resetCompanylogo(state, data) {
            state.user.company.company_logo = data
        }
    },
    getters: {
        user: (state) => state.user
    }

}